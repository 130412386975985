import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import { Item, Slider } from '../components/main'
import { Col } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import '../style/gold2.css'
import path from '../assets/img/humayri/path1.svg'
import music from '../assets/music/hum.aac'
import logoig from '../assets/img/humayri/logoig.svg'
import path3 from '../assets/img/gold1/path3.svg'
import path2 from '../assets/img/gold1/path2.svg'
import bunga6 from '../assets/img/bunga6.png'
import logo from '../assets/img/logo.ico'
import { gambar } from '../params'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import post from '../params/post'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { cap } from '../params'



export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()

    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      clicked: 0,
      sesi: 1,
      hadir: false
    }
  }

  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 1000
    });

    var countDownDate = new Date("12/12/2020").getTime();

    // Update the count down every 1 second
    var x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })

    }, 1000);

  }
  play = () => {
    window.scrollTo({
      top: this.myRef.current.offsetTop,
      behavior: 'smooth'
    })
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('sectiongold24');
      elmnt.scrollIntoView();
    }, 1000)
    setTimeout(() => {
      AOS.refresh()
    }, 1100)

  }
  googleCalendar = () => {
    window.open(`https://calendar.google.com/event?action=TEMPLATE&tmeid=NnR1ZTl2MmJvN3J2dDljbG11djFiZWY1OHQgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com`, '_blank')
  }

  handleSubmit = async () => {
    let local = localStorage.getItem('pesan')
    let err = []
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "humayri-marsha", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }



  }
  useQuery = () => {
    return new URLSearchParams(this.props.location.search);

  }

  render() {
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''
    let { clicked, acara, days, hours, minutes, seconds, hide, sesi, hadir, err, submitted } = this.state

    return (
      <>
        <Helm
          title='Undanganku - Marsha & Humayri'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
        />
        <div id='gold2' style={{ backgroundColor: '#1F467A' }}>
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw'
          }}>
            <div className="jumbotrongold2 jumbotron-fluid mb-0 w-100 pt-5" id='sectiongold21' style={{
              overflow: 'hidden', maxWidth: '100vw',
              backgroundImage: `url(${gambar('https://i.ibb.co/3hLVxJP/Modal-Depan-Dalam-Fix.jpg',
                95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1200&h=900')})`
            }}>
              <div className={`path w-100 ${hide ? 'hide' : 'show'}`}>
                <img src={path} className='img-fluid w-100' />
              </div>

              <div className="container" >
                <Item>
                  <Col xs={12} sm={4}>
                    <img className='img-fluid w-100' src={gambar('https://i.ibb.co/FXY17DR/Logo-2-fix.png')} />
                  </Col>
                </Item>
                <Item>
                  {query ? (
                    <h2 className={`col-md-4 roboto-slab text-center pt-5 pt-sm-3`}> Kepada : {query} </h2>

                  ) : false}</Item>
                {!hide ? false : (
                  <div className='tombol'>
                    <Item>
                      <button
                        onClick={() => {
                          this.play()
                        }}
                        style={{
                          marginTop: !query ? '100px' : 0
                        }}
                      >Open Invitation</button>
                    </Item>
                  </div>
                )}
              </div>
            </div>
            <div className={hide ? 'd-none' : 'd-block'}>
              <Container id='sectiongold24' className='pb-md-5' ref={this.myRef} style={{ backgroundColor: '#1F467A' }}>
                <div className='path w-100' >
                  <img src={path3} className='img-fluid w-100' />
                </div>
                <div className='pt-5 pb-5'>

                  <Item>
                    <Col xs={4} lg={1}>
                      <img src={path2} className='img-fluid w-100' data-aos="fade-left" data-aos-duration="1000" />
                    </Col>
                  </Item>
                  <Item>
                    <p className='text-center col-md-8 quotes pt-3 pb-md-5' data-aos="fade-left"
                      data-aos-duration="1000">
                      Dan di antara tanda-tanda (kekuasaan) Nya adalah Dia menciptakan untuk kamu pasangan-pasangan (hidup) dari jenis kamu sendiri, supaya kamu tenang kepadanya, dan dijadikan-Nya di antara kamu mawaddah dan rahmat. Sesungguhnya pada yang demikian itu benar-benar terdapat bukti-bukti bagi kaum yang berpikir (tentang kuasa dan nikmat Allah swt).
                       <br /><br />
                    </p>
                  </Item>

                </div>
              </Container>
              <Container fluid className='bg-white'>
                <Item>
                  <Col xs={12} sm={8}>
                    <div className='opening pb-sm-4' data-aos="zoom-in">
                      <p style={{ color: 'grey' }} className='text-center poppins font18'>
                        Maha Suci Allah yang telah menciptakan makhluk-Nya secara Berpasang-pasangan.
                        Ya Allah perkenankan kami merangkai kasih sayang yang kau ciptakan diantara Putra Putri kami.
                      </p>
                    </div>
                  </Col>
                </Item>

              </Container>
              <Container id='mempelai' fluid className='bg-white pb-5'>
                <Item>
                  <Col xs={12} md={10}>
                    <Item>
                      <div className=' col-xs-12 col-sm-5' data-aos="fade-left" data-aos-duration="1000" >
                        <div className='kotak mr-lg-2'
                          style={{ backgroundColor: '#1F467A' }}>
                          <Item>
                            <h1 style={{ fontSize: '1.75rem', color: '#F0C04F' }}>
                              Humayri Sidqi
                            </h1>
                          </Item>
                          <Item>
                            <Col xs={6}>
                              <img src={gambar('https://i.ibb.co/XDJ1T12/Profil-1.jpg')} className='img-fluid w-100 rounded' />
                            </Col>
                          </Item>

                          <Item>
                            <p className='text-center' style={{ fontSize: '1.3rem' }}>
                              Putra dari :<br />
                              Bapak Djumadi<br />
                              dan<br />
                              Ibu Emalia<br />
                            </p>
                          </Item>
                          <Item>
                            <Col xs={3} style={{ opacity: 0 }}>
                              <img src={logoig} className='img-fluid w-100' />
                            </Col>
                          </Item>
                        </div>
                      </div>
                      <Col xs={12} sm={2} className='align-middle my-auto pt-3 pt-sm-0'>
                        <h1 className="text-center align-middle " style={{ fontSize: '1.75rem', color: '#1F467A' }}>
                          Dengan
                          </h1>
                      </Col>

                      <div className=' col-xs-12 mt-3 mt-lg-0  col-sm-5' data-aos="fade-right" data-aos-duration="1000">
                        <div className='kotak mr-lg-2' style={{ backgroundColor: '#1F467A' }}>
                          <Item>
                            <h1 style={{ fontSize: '1.75rem', color: '#F0C04F' }}>
                              Marsha Widia Rahma
                            </h1>
                          </Item>
                          <Item>
                            <Col xs={6}>
                              <img src={gambar('https://i.ibb.co/kJ595PD/Profil-2.jpg')} className='img-fluid w-100 rounded' />
                            </Col>
                          </Item>

                          <Item>
                            <p className='text-center' style={{ fontSize: '1.3rem' }}>
                              Putri Dari<br />
                              Bapak Bambang Budiatno<br />
                              dan<br />
                              Ibu Wiwiek Pertiwi<br />
                            </p>
                          </Item>
                          <Item>
                            <Col xs={3}>
                              <img src={logoig} className='img-fluid w-100 btn p-0' onClick={() => {
                                window.open('http://instagram.com/marshawr')
                              }} />
                            </Col>
                          </Item>
                        </div>
                      </div>
                    </Item>
                  </Col>
                </Item>
              </Container>


              <Container id='sectiongold27' style={{ backgroundColor: '#1F467A' }} fluid>
                <div className='pt-3 ml-3 mr-3'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <Col xs={12} md={6} className='b-blue katok  text-center' style={{ color: '#F0C04F', backgroundColor: '#0F233D !important' }}>
                      <div data-aos='fade-left' data-aos-duration="1000">
                        <Item>
                          <h1 style={{ color: '#F0C04F' }}>
                            Akad Nikah
                           </h1>
                        </Item>
                        <Item>
                          <p className='bold italic' style={{ color: '#F0C04F' }}>
                            12 Desember 2020</p><br />
                        </Item>
                        <Item>
                          <p className='italic' style={{ color: '#F0C04F' }}>
                            <b>HIS LIPI GRAND BALLROOM</b><br />
                                  Jalan Gatot Subroto No. 10, Kuningan Barat<br />
                                  Mampang Prapatan, Kota Jakarta Selatan, DKI Jakarta, 12730
                                </p>
                        </Item>
                        <Item>
                          <p className='mt-2' style={{ color: '#F0C04F' }}>
                            07.30 - 09.30 WIB
                          </p>
                        </Item>
                      </div>
                      <div data-aos={`fade-left`} data-aos-duration="1000" className='pt-4'>
                        <Item>
                          <h1 style={{ color: '#F0C04F' }}>
                            Resepsi
                          </h1>
                        </Item>
                        <Item>
                          <p className='bold italic' style={{ color: '#F0C04F' }}>
                            12 Desember 2020
                          </p>
                          <br />
                        </Item>
                        <Item>
                          <p className='italic' style={{ color: '#F0C04F' }}>
                            <b>HIS LIPI GRAND BALLROOM</b><br />
                               Jalan Gatot Subroto No. 10, Kuningan Barat<br />
                               Mampang Prapatan, Kota Jakarta Selatan, DKI Jakarta, 12730
                          </p>
                        </Item>
                        <Item>
                          <p className='mt-2' style={{ color: '#F0C04F' }}>
                            Sesi 1 : 10.30 - 11.30 WIB<br />
                                Sesi 2 : 11.30 - 13.00 WIB
                          </p>
                        </Item>
                      </div>
                    </Col>
                  </Item>
                  <Item>
                    <div data-aos="zoom-in" data-aos-duration="1000"
                      className='col-6 col-lg-4 mt-3 button text-center btn p-0'
                  
                      onClick={() => {
                        window.open('https://www.google.com/maps/dir//HIS+LIPI+GRAND+BALLROOM+Jalan+Gatot+Subroto+No.+10,+Kuningan+Barat+Mampang+Prapatan,+Kota+Jakarta+Selatan,+DKI+Jakarta,+12730/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x2e69f337b75f4001:0x8c0b6409897fcebd?sa=X&ved=2ahUKEwi0jYu3vtDsAhXTlOYKHV8FAyUQ9RcwE3oECCMQBQ')
                      }}>
                      Google Maps
                    </div>
                  </Item>
                  <Item>
                    <div data-aos="zoom-in" data-aos-duration="1000"
                     className='col-6 col-lg-4 mt-3 button text-center smaller btn p-0' onClick={() => {
                      this.googleCalendar()
                    }} >
                      Tambahkan ke Google Calendar
                </div>
                  </Item>
                  <Item>
                    <div className='col-12 col-sm-8 pt-3'>
                      <p data-aos="zoom-in" data-aos-duration="1000" className='italic red text-center' style={{ fontSize: '16px' }}>
                        Diberitahukan kepada setiap tamu undangan untuk mengikuti protokol kesehatan dalam menghadiri acara Akad Nikah & Resepsi</p>
                    </div>
                  </Item>
                  <Item>
                    <div data-aos="fade-left" data-aos-duration="1000" className='col-10 col-lg-8 kotak' style={{ backgroundColor: '#F0C04F' }}>
                      <Item>
                        <div className='item'>
                          <Item>
                            <div>
                              {days}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Hari
                      </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {hours}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Jam
                      </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div >
                              {minutes}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Mnt
                      </span>
                          </Item>
                        </div>
                        <div className='dot' >:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {seconds}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Dtk
                      </span>
                          </Item>
                        </div>
                      </Item>

                    </div>
                  </Item>
                </div></Container>
              <Container className='mt-3' data-aos="fade-right" data-aos-duration="1000" fluid style={{ backgroundColor: '#1F467A' }}>
                <Slider slide={[
                  gambar('https://i.ibb.co/SK9Z1Sj/1.jpg', 95,
                    'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),
                  gambar('https://i.ibb.co/Gn1t4YH/2.jpg', 95,
                    'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),
                  gambar('https://i.ibb.co/V2nVfTp/3.jpg', 95,
                    'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),
                  gambar('https://i.ibb.co/tMpPYfK/4.jpg', 95,
                    'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),
                  gambar('https://i.ibb.co/yQ0dFrx/5.jpg', 95,
                    'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),
                  gambar('https://i.ibb.co/qjVQgjg/6.jpg', 95,
                    'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),
                ]} />
              </Container>
              <Container id='sectiongold28' >
                <Col xs={12} className='pt-5 pl-sm-5 pr-sm-5 text-center'>
                  <Alert variant='warning'>
                    <p style={{ fontSize: '14px' }}>
                      Ya Allah<br />
Tentramkanlah antara kedua mempelai ini<br />
Sebagaimana Engkau telah menentramkan antara<br />
Nabi Adam dan Hawa<br />
Nabi Yusuf dan Julaikha<br />
Junjungan kami Nabi Muhammad SAW dan Khadijah Al Kubra<br /><br />

Ya Allah<br />
Panjangkanlah umur kami<br />
Terangilah hati kami<br />
Teguhkanlah iman kami<br />
Baguskanlah amal perbuatan kami<br />
Lapangkanlah rizki kami<br />
Kabulkanlah hajat kami<br />
Guna mendapatkan ridho-Mu dan kebajikan<br /><br />

Semoga Allah<br />
Melimpahkan sholawat dan salam<br />
Atas junjungan kami<br />
Nabi Muhammad SAW, keluarga<br />
Dan para sahabatnya<br />
                    </p>

                  </Alert>
                </Col>
                <div className='pt-3 mt-4 mt-lg-5'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="fade-left">
                      <Item>
                        <h1 style={{ color: '#F0C04F' }}>
                          Kirim  Ucapan
                        </h1>
                      </Item>
                      <Item>
                        <form className="col-12 w-100 jefri">
                          <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' defaultValue={query ? query : ''} />
                          <input ref={this.alamat} type='text' className="col-12 w-100 text-center" placeholder="Alamat" name='alamat' />
                          <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                          <Item>
                            <Col xs={6} onClick={() => {
                              this.setState({ hadir: true })
                            }
                            }>
                              <input id="option" type="radio" checked={hadir ? true : false} />
                              <label ><span><span></span></span>Hadir</label>

                            </Col>
                            <Col xs={6} onClick={() => {
                              this.setState({ hadir: false })
                            }
                            }>
                              <input id="option" type="radio" checked={hadir ? false : true} />
                              <label ><span><span></span></span>Tidak Hadir</label>

                            </Col>
                          </Item>

                          <Item>
                            <Col xs={12} className=''>
                              {
                                submitted == true ? (
                                  <Alert variant='success' style={{ fontSize: '16px' }}>
                                    Pesan anda sudah disampaikan
                                  </Alert>) : (submitted === false ? (
                                    <Alert variant='danger' style={{ fontSize: '16px' }}>
                                      {
                                        err.map(val => {
                                          return (
                                            <li>{val}</li>
                                          )
                                        })
                                      }

                                    </Alert>
                                  ) : false)
                              }

                            </Col>
                          </Item>
                          <Item>
                            <div  className='col-6 button rounded btn p-0' 
                            style={{ backgroundColor: '#F0C04F' }} 
                            onClick={() => this.handleSubmit()}> Kirim </div>
                          </Item>
                        </form>
                      </Item>
                    </div>
                  </Item>
                  <h2 data-aos="fade-right">Marsha & Humayri</h2>
                </div>
              </Container>
              <Container id='sectiongold210' >
                <div className='path w-100 '>
                  <img src={path3} className='img-fluid w-100 ' />
                </div>
              </Container>
              <Container className='text-center pb-5' id='sectiongold29'>
                <Item>
                  <h1 >
                    Possible Wedding
              </h1>
                </Item>
                <Item>
                  <h2  >
                    Digital Invitation
              </h2>
                </Item>
                <Item>
                  <Col md={8}>
                    <Item>
                      <div className='col-3 col-lg-1'>
                        <img src={logoig} className='img-fluid w-100'
                        />
                      </div>
                    </Item>
                  </Col>
                </Item>
              </Container>
            </div>
          </div>
        </div>
      </>


    )
  }
}